import './Disclaimer.css';
import { useEffect } from "react";

const Disclaimer = () => {
  return (
    <div className="DISCLAIMERbig">
        <div className="DISCLAIMERsmall">Website still under development!</div>
    </div>
  );
}

export default Disclaimer;